.service_section{
    padding: 0 55px;
    display: flex;
    position: relative;
    transition: all 3s;
}

.service_carousel{
    display: flex;
}

.service_cards{
    max-width: 350px;
    height: 490px;
    padding: 30px;
    text-align: center;
    box-shadow: 0 0 5px #F2C010;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    transition: all .5s ease-in-out;
}


.service_cards:hover{
    background-color: #F2C010;
}

.service_cards button{
    transition: all 1s ease-in-out;
}
.service_cards:hover button{
    background: rgb(255, 255, 255)!important;
    box-shadow: 0 0 5px #ffffff;
}
.service_cards:hover .service_title p{
    background: rgb(255, 255, 255);
    box-shadow: 0 0 5px #ffffff;
    border: 1px solid #ffffff;
    transition: all .5s ease-in-out;
}

.service_title p{
    border: 1px solid #F2C010;
    display: inline-block;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 20px auto;
    margin-bottom: 0;
    font-size: 1.4em;
    transition: all .5s ease-in-out;
}

.service_title p span{
    font-size: .7em;
}

.serve_description{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.serve_description p{
    padding-bottom: 25px;
}


.carousel_sec{
    margin: 10px 0;
}


/* Modal */
.modal_block{
    margin:1em -100%; /* выйти из контейнера */
    margin:1em calc(50% - 50vw); 
    margin-top: 0;
    background-color: #00000040;
    position: absolute;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 1000;
}
.modal{
    left: 50%;
    top: 50%;
    transform: translate(-50%, -48%);
    position: absolute;
    overflow:unset;
    width: 60vw;
    height: 40vw;
    background-color: rgba(245, 245, 245, 0.349);
    backdrop-filter: blur(20px);
    animation: modal .5s ease-in;
    padding: 25px 35px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.479);

}

.close_btn{
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    transition: all .2s ease-in-out ;
}
.close_btn:hover{
    transform: translateY(-5px) rotate(180deg);
    color: #F2C010;
}

.service_carousel{
    margin: 0 10px;
}

.modal_title{
    font-size: 2em;
    padding-bottom: 5px;
}
.detail_element{
    padding: 10px 0;
}
.detail_element h3{
    font-size: 1.8em;
    padding-bottom: 10px;
    color: #F2C010;
}
.detail_element ul{
    padding-bottom: 10px;
}
.detail_element ul li{
    padding-bottom: 5px;
    list-style-type: disc;
    font-size: 1.5em;
}
.detail_element p{
    padding-bottom: 10px;
    font-size: 1.5em;
}
.sub_title{
    font-weight: 500;
    padding-bottom: 10px;
}
@keyframes modal {
    0%{
        left: 50%;
        top: 50%;
        transform: translate(-50%, -43%);
        opacity: 0;
    }
    100%{
        left: 50%;
        top: 50%;
        transform: translate(-50%, -48%);
        opacity: 1;
    }
}
@media only screen and (max-width: 1800px) {
    .detail_element p{
        font-size: 1.3em;
    }
    .detail_element ul li{
        font-size: 1.3em;
    }
}
@media only screen and (max-width: 1640px) {
    .detail_element p{
        font-size: 1em;
    }
    .detail_element ul li{
        font-size: 1em;
    }
}
@media only screen and (max-width: 1400px) {
    .service_title h2{
        font-size: 1.2em;
    }
    .service_title p{
        font-size: 1.1em;
    }
    
    .modal{
        overflow: scroll;
        overflow-x: hidden;
    }
::-webkit-scrollbar {
    width: 10px;
  }
  
   
  ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.521); 
    border-radius: 5px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
}
@media only screen and (max-width: 878px) {
    .modal{
        height: 500px;
    }
}