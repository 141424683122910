.price_block h1{
    font-size: 2.4em;
    padding: 10px 0;
    margin: 10px 0 30px; 
    text-align: center;
    border-bottom: 1px solid #000;
    line-height: 0.1em;
}
.price_block h1 span { 
    background:#fff;
    padding:0 10px; 
}

.price_un_block{
    background-color: #fff;
    width: 750px;
    padding: 100px 0;
    margin: 100px auto;
    border-radius: 30px;
    position: relative;
}

.price_un_block img{
    position: absolute;
    right: 20px;
    top: 10px;
}

.price_list{
    max-width: 725px;
    margin: 0 auto;
    position: relative;
    padding: 100px 35px;
    background-color: #fff;
    box-shadow: 0 0 20px rgb(207, 207, 207);
    border-radius: 20px;
}

.price_list h2{
    padding-bottom: 25px;
    text-align: center;
}

.price_list ul li{
    padding: 10px 0;
    font-size: 1.5em;
}

.prices_title ul li{
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.prices{
    width: 100px;
    text-align: left;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.prices span{
    display: block;
    font-size: .6em;
    text-align: right;
    
}
@media only screen and (max-width: 800px) {
    .price_un_block{
        width: 100%;
    }
    .price_un_block h1{
        font-size: 1.9em;
    }
    .price_list{
        max-width: none;
        width: 100%;
    }
}
@media only screen and (max-width: 684px) {
    .prices_title ul li{
        font-size: 1.3em;
    }
    .prices{
        width: inherit;
    }
    .prices span{
        padding-left: 15px;
    }
}