.sub_section{
    display: flex;
    align-items: center;
    justify-content: space-around;
}


.images img:first-child{
    max-width: 680px;
    width: 100%;
}

.reversed_section{
    flex-direction: row-reverse;
}

.images_block{
    position: relative;
    overflow: hidden;
}

.images{
    position: relative;
}



.hourglass{
    position: absolute;
    left: 40%;
    bottom: 40%;
    animation: hourglass 10s infinite ease-in-out;
}
.shadow{
    position: absolute;
    left: 40%;
    bottom: 25%;
    animation: hourglass 10s infinite ease-in-out;
}

@keyframes hourglass {
    0%{
        transform: rotate(0);
    }
    33%{
        transform: rotate(-7deg);
    }
    70%{
        transform: rotate(10deg);
    }
    100%{
        transform: rotate(0)
    }
}

.help{
    animation: shakeb 3s infinite ease-in-out;
}
@keyframes shakeb {
    0% {
      transform: translate(0, 0);
    }
    20% {
      transform: translate(3px, 0);
    }
    70% {
      transform: translate(-3px, 0);
    }
    100% {
      transform: translate(0, 0);
    }
  }

  .question{
    left: 46%;
    position: absolute;
    top: 40%;
    width: 10%;
    animation: shake 3s infinite ease-in-out;
}
@keyframes shake {
    0% {
      transform: translate(0, 0);
    }
    20% {
      transform: rotate(-10deg) scale(1.2);
    }
    70% {
      transform: rotate(13deg) scale(1.5);
    }
    100% {
      transform: translate(0, 0);
    }
  }

.plus{
    position: absolute;
    left: 0;
    width: 15%;
    animation: plus 5s 2s infinite ease-in-out;
}

@keyframes plus {
    0%{
        transform: translateY(0)
    }
    50%{
        transform: translateY(100%);
    }
    100%{
        transform: translateY(0);
    }
}

.circle1{
    position: absolute;
    top: 0;
    left: 20%;
    width: 5%;
    animation: plus 5s 3s infinite ease-in-out;
}
.circle2{
    position: absolute;
    top: 20%;
    left: 0%;
    width: 7%;
    animation: plus 5s 6s infinite ease-in-out;
}



.hided .floating-code1{
    position: absolute;
    bottom: 10%;
    right: -100%;
    width: 50%;
}
.show .floating-code1{
    position: absolute;
    bottom: 10%;
    right: 20%;
    width: 50%;
    transition: all 1.7s ease;
    transition-delay: 1s;
}
.hided .floating-code0{
    position: absolute;
    right: -100%;
    bottom: 10%;
    width: 70%;
    opacity: 0;
}

.show .floating-code0{
    position: absolute;
    bottom: 10%;
    right: 0;
    transition: all 2s ease;
    width: 70%;
    transition-delay: 1s;
}

.floating-win{
    position: absolute;
    top: 22%;
    left: 10%;
    width: 60%;
    animation: win 5s 6s infinite ease-in-out;
}

@keyframes win {
    0%{
        transform: scale(.9);
    }
    50%{
        transform: scale(1.1);
    }
    100%{
        transform: scale(.9);
    }
}

.med{
    position: absolute;
    left: 50%;
    width: 35%;
    top: 20%;
    animation: cloud 5s infinite ease-in-out;
}

@keyframes cloud {
    0%{
        transform: translateY(0);
    }50%{
        transform: translateY(30px);
    }100%{
        transform: translateY(0);
    }
}

.sm-cloud{
    position: absolute;
    left: 45%;
    width: 25%;
    top: 38%;
    animation: cloud 10s infinite ease-in-out;
}

.section_text{
    width: 100%;
    max-width: 480px;
    padding: 15px 30px;
    margin-left: auto;
    margin-top: auto;
}

.section_text h2{
    font-weight: 600;
    font-size: 35px;
    padding-bottom: 10px;
}

.blue_text{
    color: #65C8F6;
}
.dark_blue{
    color: #00699A;
}

.section_text button{
    width: 170px;
    height: 40px;
    border: 0;
    border-radius: 135px;
    background-color: #F2BE45;
    font-weight: 700;
    font-size: 1em;
    line-height: 15px;
}

.paragraphs{
    font-size: 1.125em;
    line-height: 22px;
    font-weight: 300;
}

.paragraphs p{
    margin-bottom: 15px;
}

.text_block{
    background-color: #ffffff40;
    backdrop-filter: blur(10px);
    z-index: 1;
    border-radius: 10px;
}

.hided{
    opacity: 0;
    transform: translateY(-30%);
}

.show{
    opacity: 1;
    transform: translateY(0);
    transition: all 2s ease-out;
}

@media only screen and (max-width: 768px) {
    .sub_section{
        flex-direction: column;
    }
    .section_text h2{
        padding: 15px 0;
    }
}
@media only screen and (max-width: 500px) {
        
    .images img{
        max-width: 400px;
    }
}