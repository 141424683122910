.section_block{
    height: 80vh;
    display: flex;
    align-items: center;
    vertical-align: middle;
    justify-content: space-around;
}


.reversed{
    flex-direction: row-reverse;
}

.image_side{
    position: relative;
}

.image_side img:first-child{
    max-width: 675px;
    width: 100%;
}

.image_side img:nth-child(2){
    position: absolute;
    right: 0;
    bottom: 0;
    max-width: 140px;
}

.reversed .image_side img:nth-child(2){
    left: -5%;
}

.wins img:nth-child(2){
    position: absolute;
    right: -30%;
    max-width:none;
}
.wins img:nth-child(3){
    position: absolute;
    right: -30%;
    top: -20%;
}

.text_side_title{
    padding-bottom: 10px;
}
.text_side_title h2{
    font-size: 41px;
    font-weight: 700;
    color: #2e4051;
}
.text_side_title p{
    font-weight: 300;
    font-size: .8em;
    color: #60bff1;
}


.text_side{
    max-width: 500px;
    width: 100%;
}

.text_side_subs p{
    max-width: 400px;
    padding-bottom: 10px;
    font-weight: 300;
    font-size: 18px;
}
@media only screen and (max-width: 1670px) {
    .wins{
        max-width: 500px;
    }
    
    .wins img:nth-child(2){
        position: absolute;
        right: -30%;
        max-width:none;
        width: 80%;
    }
    .wins img:nth-child(3){
        position: absolute;
        width: 80%;
        right: -30%;
        top: -20%;
    }
}

@media only screen and (max-width: 1335px) {
    .wins{
        max-width: 400px;
    }
}


@media only screen and (max-width: 1155px) {
    .wins{
        max-width: 300px;
    }
}

@media only screen and (max-width: 1024px) {
    .section_block{
        padding: 0 0 50px 0;
        justify-content: space-around;
    }
    .image_side img{
        max-width: 450px;
    }
    .text_side{
        width: inherit;
    }
}

@media only screen and (max-width: 915px) {
    .wins{
        max-width: 200px;
    }
}

@media only screen and (max-width: 800px) {
    .section_block{
        flex-direction: column;
    }
    .reversed .text_side_title{
        text-align: right;
    }
    .text_side_subs p{
        max-width: none;
        width: 100%;
    }
    .wins{
        max-width: 400px;
        transform: translateX(-20%);
    }
    .reversed .image_side img:nth-child(2){
        left: 0%;
    }
}
@media only screen and (max-width: 500px) {
    .text_side_title{
        text-align: center;
    }
    .reversed .text_side_title{
        text-align: center;
    }
    .text_side_subs{
        text-align: center;
    }
}