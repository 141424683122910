.header{
    display: flex;
    height: 100vh;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    position: relative;
}
.left_side_header{
    display: flex;
    flex-direction: column;
}

.left_side_title{
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
}

.left_side_title h1{
    font-size: 1.8em;
    max-width: 800px;
}

.left_side_title span{
    color: #F2C010;
}

.title_logo{
    width: 80px;
}

.left_side_title img{
    max-width: 290px;
}


.left_side_header p{
    max-width: 500px;
    line-height: 30px;
    font-size: 1.5em;
}

.left_side_header button{
    margin-top: 50px;
}


.right_side_imgs{
    display: flex;
    position: relative;
}

.right_side_imgs img{
    max-width: 565px;
}

@media only screen and (max-width: 800px) {
    .header{
        flex-direction: column-reverse;
        justify-content: center;
    }
    .left_side_header{
        padding-top: 20px;
        text-align: center;
    }
    .left_side_header p{
        max-width: none;
    }
}
@media only screen and (max-width: 500px) {
    .right_side_imgs img{
        width: 100%;
    }
    .left_side_header{
        text-align: center;
    }
}