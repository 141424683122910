.contacts{
    padding: 130px 0;
}
.contacts_block{
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1100px;
    margin: 0 auto;
    margin-bottom: 90px;
    border-radius: 40px;
}

iframe{
    border: 0;
    width: 350px;
    height: 350px;
    border-radius: 15px;
    filter: invert(90%)
}

.form_block{
    max-width: 700px;
    width: 100%;
}

.form_block img{
    width: 150px;
}
.form_title{
    text-align: center;
    font-size: 2em;
    font-weight: 700;
    padding-bottom: 50px;
    color: #1b2630;
}
.form_inputs{
    display: flex;
    flex-direction: column;
}

.form_inputs button{
    margin: 0 auto;
    height: auto;
    width: auto;
    padding: 15px 45px;
}

.form_inputs input{
    margin: 0 auto;
    max-width: 500px;
    width: 100%;
    padding: 15px 10px 12px 10px;
    margin-bottom: 15px;
    border: 1px solid #e2e0e0;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    transition: all .4s ease-in-out;
}

.form_inputs input::placeholder{
    font-size: 1.5em;
}

.form_inputs input:focus{
    outline: 0;
    box-shadow: 0 0 10px rgba(0, 68, 68, 0.548);
}

@media only screen and (max-width: 900px) {
    .contacts_block{
        flex-direction: column;
    }
    .frame_block{
        width: 100%;
        margin-bottom: 10px;
    }
    .form_inputs{
        padding-bottom: 20px;
    }
    iframe{
        border-radius: inherit;
        width: 100%;
        height: 200px;
    }
}
@media only screen and (max-width: 500px) {
    .form_title{
        font-size: 1.5em;
    }
    .form_inputs input::placeholder{
        font-size: 1em;
    }
    .contacts_block{
        border-radius: 0;
    }
}