
.swiper {
  width: 100%;
  height: 100%;
}

.mySwiper{
  height: 100%!important;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
}


h1{
  text-align: center;
  padding: 70px 0;
}
.partners{
  display: flex;
  justify-content: space-around;
  align-items:center;
  padding-bottom: 100px;
}

.partners_item{
  max-width: 200px;
}

.partners_item img{
  width: 100%;
}

.customers_container{
  display: flex!important;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 60px;
}

.customer_logo{
  max-width: 200px;
}

.customer_logo img{
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .partners{
    padding-bottom: 0;
  }
  .partners_item{
    max-width: 80px;
  }
  .customer_logo{
    max-width: 120px;
  }
}


@media only screen and (max-width: 500px) {
  .partners{
    flex-direction: column;
  }
  .partners_item{
    max-width: 200px;
    margin-bottom: 80px;
  }

  .customers_container{
    flex-wrap: wrap;
    margin-bottom: 0;
  }

  .customer_logo{
    max-width: 150px;
    margin: 40px;
  }
}
