footer{
    height: 75vh;
    background-color: #f3f3f3;
    position: relative;
    margin-top: 30px;
    margin:1em -100%; /* выйти из контейнера */
    margin:1em calc(50% - 50vw); 
    margin-bottom: 0;
}

.bg_img{
    position: absolute;
    left: -100px;
    bottom: -50px;
    width: 300px;
    opacity: .1;
}

footer button{
    margin: 0;
    border-radius: 0;
}

.footer_order{
    /* background-color: #b4c1fc; */
    padding: 20px 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.footer_order h2{
    font-weight: 400;
}

.behind_block{
    width: 70%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    /* background-color: rgba(198, 223, 243, 0.384); */
    background: linear-gradient(45deg, #cfe7fc80, #f3f3f3);
    background-size: 600% 600%;
    animation: color 12s ease-in-out infinite;
    backdrop-filter: blur(5px);
    padding: 25px 35px;
    top: -50px;
    box-shadow: 0 0 15px #4060f0 ;
}

@keyframes color{
    0%{
        background-position: 0 50%;
    }
    50%{
        background-position: 100% 50%;
    }
    100%{
        background-position: 0 50%;
    }
}

.footer_list{
    display: flex;
    justify-content: space-around;
    align-items: center;
    vertical-align: middle;
    height: 100%;
}

.footer_left_side{
    width: 20vw;
}

.footer_left_side img{
    width: 70%;
}

.footer_left_side h3{
    font-size: 4em;
    color: #00000091;
    font-weight: 300;
}

.footer_left_side p{
    padding-left: 5px;
    width: 45vw;
}

.footer_right_side{
    display: flex;
    justify-content: space-between;
    vertical-align: middle;
    align-items: center;
}

.footer_right_side h4{
    padding-bottom: 5px;
    font-weight: 500;
    padding-top: 15px;
}
.footer_right_side p{
    padding-bottom: 10px;
    max-width: 400px;
}
.footer_right_side ul{
    max-width: 300px;
    padding-top: 15px;
    padding-right: 20px;
}
.footer_right_side li{
    padding-bottom: 10px;
    font-weight: 500;
}

.footer_copyright{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    font-size: .7em;
}


.footer_copyright h3{
    font-weight: 500;
}

.my_copyright{
    font-weight: 700;
    font-size: 1.1em;    
}

.my_copyright h3{
    font-weight: 400;
}

@media only screen and (max-width: 1024px) {
    .footer_right_side{
        flex-direction: column-reverse;
        align-items: baseline;
        justify-content: space-between;
    }
    .footer_left_side{
        width: inherit;
    }
}
@media only screen and (max-width: 768px) {
    .footer_left_side p{
        width: 100%;
    }
    .footer_list{
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    .footer_right_side{
        align-items: center;
    }
    .behind_block{
        display: none;
    }
    footer{
        padding: 50px 0 0 0;
    }
    .footer_copyright{
        width: 100%;
        text-align: center;
    }
    .footer_right_side ul{
        padding-right: 0px;
    }
}
