*{
    list-style-type: none;
}

.mob_menu{
    display: none;
}

.header{
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 100;
}

.logo_block{
    display: flex;
    align-items: center;
    padding-left: 15px;
}

.logo_block img{
    height: 44px;
    padding: 3px 0;
}

.logo_block h3 img{
    padding-left: 10px;
    padding-top: 5px;
    width: 130px;
    height: auto;
}

.link{
    transition: all .3s ease-in-out;
}

.link:hover{
    color: #F2BE45;
}

.nav_bar ul{
    display: flex;
    align-items: center ;
}
.nav_bar_mob ul{
    display: flex;
    flex-direction: column;
    align-items: start;
}
.nav_bar_mob ul li{
    font-size: 26px;
    font-weight: 200;
    margin-bottom: 15px;
}

.nav_bar ul li{
    font-size: 22px;
    font-weight: 200;
    margin-left: 30px;
}
.nav_bar ul li:first-child{
    margin-left: 0;
}

.side_links{
    display: flex;
    font-size: 18px;
    font-weight: 200;
    padding-right: 55px;
}

.side_links li:first-child{
    margin-right: 15px;
}

.lang_select{
    padding: 2px 15px;
    border: 0;
    margin-right: 20px;
    background-color: transparent;
}

.mob_header{
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 5px 0;
    align-items: center;
}

.mob_right{
    margin-right: 20px;
    display: flex;
    align-items: center;
}

.menu_mob{
    font-size: 23px;
}
.mob_driwer{
    position: relative;
}

.mob_driwer button{
    width: 30px;
}

.drawer_mob_footer li{
    margin-bottom: 5px;
}

.drawer_mob_footer{
    position: absolute;
    bottom: 60px;
    left: 20px;
    font-size: 20px;
}

@media only screen and (max-width: 800px) {
    .mob_menu{
        position: fixed;
        width: 100vw;
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        align-items: center;
        vertical-align: middle;
        background-color: #f3f3f385;
        backdrop-filter: blur(20px);
        z-index: 100;
    }
    .header{
        display: none;
    }
}
