.section_block{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 56px 0 156px 0;
}

.inner_text_block{
    margin: 0 auto;
}

.section_block > div{
    flex: 1;
}

.text_side h1{
    font-size: 2.25em;
    font-weight: 600;
    line-height: 44px;
    padding: 10px;
}
.text_side span{
    color: #F2BE45;
}

.properties_list{
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
}

.properties_list li{
    max-width: 335px;
    width: 100%;
    padding: 10px;
}

.properties_list li h3{
    font-size: 1.25em;
    font-weight: 600;
    line-height: 1.25em;
}

.properties_list li p{
    font-weight: 400;
    line-height: 1.25em;
    padding-top: 10px;
}

.landscape{
    height: 700px;
    position: relative;
}

.landscape img{
    width: 1050px;
    position: absolute;
    right: -40%;
}

.hide1{
    transform: translateX(-400px);
}

.show1{
    transform: translateX(0);
    opacity: 1;
    transition: all 2s ease;
}

.hide2{
    transform: translateX(400px);
}

.show2{
    transform: translateX(0);
    opacity: 1;
    transition: all 2s ease;
}
.more_button{
    margin: 10px;
    height: 40px;
    width: 170px;
    border-radius: 136px;
    background-color: #B0B0B0;
}
.order_button{
    margin: 0;
    height: 40px;
    width: 170px;
    border-radius: 136px;
}

.reversed{
    flex-direction: row-reverse;
}
.reversed img{
    left: -40%;
}

@media only screen and (max-width: 1440px) {
    .properties_list li{
        max-width: 325px;
    }
    .landscape{
        height: 550px;
    }
    .landscape img{
        /* top: 50%;
        transform: translateY(-50%); */
        width: 800px;
    }
    .hide1{
        transform: translateX(-400px)!important;
    }
    
    .show1{
        transform: translateX(0)!important;
        opacity: 1;
        transition: all 2s ease;
    }
    
    .hide2{
        transform: translateX(400px);
    }
    
    .show2{
        transform: translateX(0);
        opacity: 1;
        transition: all 2s ease;
    }
}


@media only screen and (max-width: 1300px) {
    .properties_list li{
        max-width: 255px;
        font-size: .8em;
    }
    .landscape{
        height: 560px;
    }
    .landscape img{
        /* top: 50%; */
        /* transform: translateY(-50%); */
        width: 800px;
    }
}

@media only screen and (max-width: 1140px) {
    .landscape img{
        width: 700px;
    }
}
@media only screen and (max-width: 1024px) {
    .section_block{
        flex-direction: column-reverse;
        padding: 0 0 100px 0;
    }
    .properties_list{
        justify-content: center;
    }
    .inner_text_block h1{
        text-align: center;
    }
    .section_block .reversed{
        flex-direction: column;
    }
    .landscape{
        position: inherit;
    }
    .landscape img{
        width: 500px;
        position: inherit;
        /* transform: inherit; */
    }
    .properties_list li{
        max-width: 25%;
        font-size: .8em;
    }
    br{
        display: none;
    }

}
@media only screen and (max-width: 768px) {
    .properties_list li{
        max-width: 275px;
        font-size: 1em;
    }
    .section_buttons{
        text-align: center;
        padding: 20px 0;
    }
}
@media only screen and (max-width: 568px) {
    .landscape{
        display: none;
    }
}

@media only screen and (max-width: 500px) {
    .properties_list li{
        text-align: center;
    }
}