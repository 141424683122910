.header{
    display: flex;
    height: 100vh;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    position: relative;
}




.left_side_header{
    display: flex;
    flex-direction: column;
}

.left_side_title{
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
}

.title_logo{
    width: 80px;
}

.left_side_title img{
    max-width: 290px;
}


.left_side_header p{
    max-width: 500px;
    line-height: 25px;
}

.left_side_header button{
    margin-top: 50px;
}


.right_side_imgs{
    display: flex;
    position: relative;
}

.right_side_imgs img:nth-child(1){
    max-width: 565px;
    width: 100%;
}


/* Squares */

.area{
    width: 120%;
    height: 100vw;
    position: absolute;
    transform: rotate(-90deg);
}

.circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: transparent;
    border: 5px solid #deb953;
    /* background: rgba(0, 82, 129, 0.384); */
    animation: animate 25s linear infinite;
    bottom: -150px;
    
}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}



@keyframes animate {

    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}


@media only screen and (max-width: 800px) {
    .header{
        flex-direction: column-reverse;
    }
    .left_side_header h1{
        text-align: center;
    }
    .left_side_header button{ 
        margin: 50px auto;
    }
}
@media only screen and (max-width: 500px) {
    .left_side_header{
        text-align: center;
    }
    .left_side_header h1{
        font-size: 2em;
        z-index: 200;
    }
    .header{
        justify-content: center;
    }
}

